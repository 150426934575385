let service = null;

export const doCalculations = async function (reserva, listAdditionalStopsSelected) {
    let listAddresses = [];
    let total_miles = 0;
    let gpsDataFrom = JSON.parse(reserva.from);
    let gpsDataTo= JSON.parse(reserva.to);
    //add begining
    addAddressToList(
        gpsDataFrom.from,
        gpsDataFrom.coordinate,
        0,
        0,
        listAddresses
    );
    //add additional points
    for (let i = 0; i < listAdditionalStopsSelected.length; i++) {
        let data=JSON.parse(listAdditionalStopsSelected[i].to);
        addAddressToList(
            data.to,
            data.coordinate,
            0,
            0,
            listAddresses
        );
    }

    //add ending
    addAddressToList(
        gpsDataTo.to,
        gpsDataTo.coordinate,
        0,
        0,
        listAddresses
    );

    if (listAddresses.length > 1) {
        //calculate distance
       
        total_miles += await calculateDistanceIncludingAdditionalPoints(
            listAddresses, 0
        );
    }
    return total_miles;

}

export const calculateDistanceIncludingAdditionalPoints = async function (listAddresses, total_miles) {

    let listAddressesOrdered=await calculateDistancesFromStartToPoints(listAddresses);

    //set the additional point and the end destination point
    for (let i = 1; i < listAddressesOrdered.length; i++) {
        let from = listAddressesOrdered[i - 1];
        let to = listAddressesOrdered[i];

        //proccess the point to call google api map
        let searchComa = to.coordinates.indexOf(",");
        let latud = to.coordinates.substring(0, searchComa);
        let longi = to.coordinates.substring(
            searchComa + 1,
            to.coordinates.length
        );

        //longitúd y latitúd from
        let searchComaFrom = from.coordinates.indexOf(",");
        let latudFrom = from.coordinates.substring(0, searchComaFrom);
        let longiFrom = from.coordinates.substring(
            searchComaFrom + 1,
            from.coordinates.length
        );

        let origin1 = new google.maps.LatLng(latud, longi);

        //let origin2 = googleLatLng;
        let origin2 = to.address;
        let destinationA = from.address;
        let destinationB = new google.maps.LatLng(latudFrom, longiFrom);

        let originsx= [latud, longi]
        let originsx2= [latudFrom, longiFrom]
        
        //call the google maps api
        let service = new google.maps.DistanceMatrixService();
        await service.getDistanceMatrix(
            {
                origins: [origin1, origin2],
                destinations: [destinationA, destinationB],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            },
            function (response, status) {
                if (response) {
                    if (response.rows[0].elements[0].distance) {
                        let distance =
                            response.rows[0].elements[0].distance.value / 1609.34;
                        total_miles =total_miles+ distance;
                   
                    }
                }
            }
        );
    }

    return total_miles;
}

export const calculateDistancesFromStartToPoints = async function (listAddresses, listAddressesOrdered,service,googleLatLng) {
    listAddressesOrdered = [];

    //set the origin point
    let from = {
        address: listAddresses[0].address,
        coordinates: listAddresses[0].coordinates,
        distanceFromStart: 0,
        distanceBetwentPoints: 0,
    };

    //set the additional point and the end destination point
    for (let i = 0; i < listAddresses.length; i++) {
        //get the info of the current point and save into "to" variable
        let to = {
            address: listAddresses[i].address,
            coordinates: listAddresses[i].coordinates,
            distanceFromStart: null,
            distanceBetwentPoints: null,
        };

        //if is the begining or the ending dont do calculations
        if (i === 0 || i === listAddresses.length - 1) {
            listAddressesOrdered.push(to);
            continue;
        }

        //proccess the point to call google api map
        let searchComa = to.coordinates.indexOf(",");
        let latud = to.coordinates.substring(0, searchComa);
        let longi = to.coordinates.substring(
            searchComa + 1,
            to.coordinates.length
        );

        //longitúd y latitúd from
        let searchComaFrom = from.coordinates.indexOf(",");
        let latudFrom = from.coordinates.substring(0, searchComaFrom);
        let longiFrom = from.coordinates.substring(
            searchComaFrom + 1,
            from.coordinates.length
        );

        let origin1 = new google.maps.LatLng(latud, longi);
        let origin2 = to.address;
        let destinationA = from.address;

        let destinationB = new google.maps.LatLng(latudFrom, longiFrom);

        let point=[from.coordinates,'**',to.coordinates];
        let searchComax=[searchComa, searchComaFrom];
        let originx1=[latud, longi];
        let originx2=[latudFrom, longiFrom];

        // console.log('point')
        // console.log(point)
        // console.log('searchComax')
        // console.log(searchComax)
        // console.log('originx1')
        // console.log(originx1)
        // console.log('originx2')
        // console.log(originx2)

        //call the google maps api
        let service = new google.maps.DistanceMatrixService();
        await service.getDistanceMatrix(
            {
                origins: [origin1, origin2],
                destinations: [destinationA, destinationB],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            },
            function (response, status) {
                if (response) {
                    if (response.rows[0].elements[0].distance) {
                        to.distanceFromStart =
                            response.rows[0].elements[0].distance.value / 1609.34;
                            // console.log('distance result')
                            // console.log( to.distanceFromStart)

                        //add item to listAddress
                        listAddressesOrdered.push(to);

                        //order the listAddresses according to distanceFromStart
                        listAddressesOrdered.sort(function (a, b) {
                            return (
                                parseFloat(a.distanceFromStart) -
                                parseFloat(b.distanceFromStart)
                            );
                        });
                    }
                }
            }
        );
    }
    console.log(listAddressesOrdered)
    return listAddressesOrdered;
}

export const addAddressToList = function (address, coordinate, distanceFromStart, distanceBetwentPoints, listAddresses) {
    let data = {
        address: address,
        coordinates: coordinate,
        distanceFromStart: distanceFromStart,
        distanceBetwentPoints: distanceBetwentPoints
    }
    return listAddresses.push(data)
}